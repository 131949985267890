<template>
    <div class="main-container section-space--inner--100">
        <!--Service section start-->
        <div class="service-section">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="section-title-area text-center">
                            <h2 class="section-title section-space--bottom--50">
                                {{ data.serviceOne.mainConst }}
                                <br><i class="fas fa-chevron-down"></i>
                            </h2>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="service-item-wrapper">
                            <div class="row">
                                <div class="col-lg-12 col-md-6 col-12 section-space--bottom--10" v-for="mainservice in data.MainConstructionItemHome" :key="mainservice.id">
                                    <MainConstructionItem :mainservice="mainservice" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--Service section end-->

        <!-- back to top start -->
        <back-to-top class="scroll-top" bottom="60px">
            <i class="ion-android-arrow-up"></i>
        </back-to-top>
        <!-- back to top end -->
    </div>
</template>

<script>
    import data from '../data/service.json'
    import MainConstructionItem from '../components/MainConstructionItemHome'
    export default {
        components: {
            MainConstructionItem
        },
        data() {
            return {
                data,
            }
        },
        metaInfo: {
            title: 'STS CAMBODIA - Service',
            htmlAttrs: {
                lang: 'en',
                amp: true
            }
        }
    }
</script>


<template>
    <div class="service-grid-item">
        <div class="hoverStyle" align="center">
            <!-- <div class="service-grid-item__image-wrapper">
                <router-link @click.native="scrollToTop" :to="`/service-details/${ service.id }`">
                    <img :src="service.image" class="img-fluid" alt="service thumb">
                </router-link>
            </div> -->
            <div style="font-size: 120px" class="icon">
                <i :class="service.icon"></i>
            </div>
        </div>
        <div class="service-grid-item__content" align="center">
            <h3 class="title">
                <router-link @click.native="scrollToTop" :to="`/service-details/${ service.id }`">
                    {{ service.title }}
                </router-link>
            </h3>
            <p class="subtitle">{{ service.desc }}</p>
        </div>
        <router-link @click.native="scrollToTop" class="see-more-link" :to="`/service-details/${ service.id }`">SEE MORE</router-link>
    </div>
</template>

<script>
import data from '../../data/service.json'

export default {
    props: ['service'],
    data() {
        return {
            data
        }
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0, 0)
        }
    }
};
</script>
<style>
.hoverStyle {
    color: #020a49;
    transition: 0.5s;
}
.hoverStyle:hover{
    color: red;
}
</style>